<template>
  <div class="forgetPsw">
    <div class="backIcon">
      <i
        class="el-icon-back cursor fs-22 marginB15"
        :class="isPc ? '' : 'mobile-back'"
        @click="goBack"></i>
      <h2 class="fs-24 marginB26">{{$t('forget')}}</h2>
      <p class="gray marginB26">{{$t('forgetTip')}}</p>
    </div>
    <div
      v-if="!isCn"
      class="account-form marginB60">
      <div class="account-icon cursor">
        <i
          v-show="msgType == 2"
          class="iconfont icon-phone fs-20 marginR15 primary"
          @click="changeAccountType(1)"></i>
        <i
          v-show="msgType == 1"
          class="iconfont icon-Mail fs-20 primary"
          @click="changeAccountType(2)"></i>
      </div>
      <div
        v-show="msgType == 2"
        class="telphone">
        <vue-tel-input
          class='telInput'
          v-model="phone"
          :placeholder="$t('forgetAccount')"
          tabindex='2'
          @open="changeNation"
          @country-changed="changeCountry"
          @validate='validateNum'
          @blur="blurTel"
          @keyup.enter.native="nextOpaertaion">
          <template slot="arrow-icon">
            <i class='el-icon-arrow-down'></i>
          </template>
        </vue-tel-input>
      </div>
      <div
        v-show="msgType == 2"
        class="exchange telphone errorTel"
        style="margin-top: 0">
        <div class="warning">
          <i class="el-icon-warning-outline"></i>
          <span class="warningTip">{{$t('enterTelTip')}}</span>
        </div>
      </div>
      <div
        class="emailArea exchange"
        v-show="msgType == 1">
        <el-input
          class="account-input account-email"
          tabindex='2'
          v-model="account"
          maxlength="50"
          autocomplete="off"
          @keyup.enter.native="nextOpaertaion"
          @blur="blurAccount"
          :placeholder="$t('forgetAccount')"></el-input>
        <div class="warning">
          <i class="el-icon-warning-outline"></i>
          <span class="warningTip">{{$t('emailTip')}}</span>
        </div>
      </div>
    </div>
    <div
      v-if="isCn"
      class="account-form marginB60 emailArea exchange telphone">
      <div class="account-icon-cn cursor">
        <i class="iconfont icon-phone fs-20 marginR10"></i>
        <span class="line-icon"></span>
        <i class="iconfont icon-Mail fs-20"></i>
      </div>
      <el-input
        class="account-input-cn"
        tabindex='1'
        v-model="accountCn"
        maxlength="50"
        autocomplete="off"
        @keyup.enter.native="nextOpaertaion"
        @blur="blurAccountCn"
        :placeholder="$t('forgetAccount')"></el-input>
      <div class="warning">
        <i class="el-icon-warning-outline"></i>
        <span class="warningTip">{{$t('enterAccountTipCn')}}</span>
      </div>
    </div>
    <div style="overflow: hidden">
      <span
        v-if="isShowSingUp"
        class="cursor primary fs-16 fl"
        style="line-height: 39px;"
        @click='goToCreate'>{{$t('signUp')}}</span>
      <el-button
        class="sign-btn cursor fr"
        v-repeat-click="{event:nextOpaertaion}"
        :disabled='isDisabeBtn'>{{$t('next')}}</el-button>
    </div>
  </div>
</template>
<script>
import { getUrlParam } from '@/assets/js/utils'
export default {
  name: 'CreateAccount',
  data () {
    return {
      account: '',
      msgType: '1',
      phone: '',
      country: null,
      verfyObj: {},
      isDisabeBtn: true,
      isShowSingUp: true,
      isCn: false,
      accountCn: ''
    }
  },
  watch: {
    account: function (newVal) {
      if ($('.emailArea').hasClass('errorInput')) {
        $('.emailArea').removeClass('errorInput')
      }
      if (!newVal && this.msgType == 1) {
        this.isDisabeBtn = true
      } else {
        this.isDisabeBtn = false
      }
    },
    phone: function (newVal) {
      if ($('.telphone').hasClass('errorInput')) {
        $('.telphone').removeClass('errorInput')
      }
      if (!newVal && this.msgType == 2) {
        this.isDisabeBtn = true
      } else {
        this.isDisabeBtn = false
      }
    },
    vcode: function (newVal) {
      if (!newVal || !this.email) {
        this.isDisabeBtn = true
      } else {
        this.isDisabeBtn = false
      }
    },
    accountCn: function (newVal) {
      if ($('.emailArea').hasClass('errorInput')) {
        $('.emailArea').removeClass('errorInput')
      }
      if (!newVal && this.isCn) {
        this.isDisabeBtn = true
      } else {
        this.isDisabeBtn = false
      }
    }
  },
  computed: {
    location () {
      return this.$store.state.location
    },
    isPc () {
      return this.$store.state.isPc
    },
    publicServers () {
      return this.$store.state.publicServers
    }
  },
  created () {
    if (window.location.host.includes('cn')) {
      this.isCn = true
    }
    // 非公共服务不展示注册入口
    this.isShowSingUp = this.publicServers.includes(getUrlParam('serviceName'))
  },
  methods: {
    goBack () {
      ga('send', 'event', 'newUserService-forgetPsw-goBack', 'click')
      this.$router.go(-1)
    },
    goToCreate () {
      ga('send', 'event', 'newUserService-forgetPsw-leave', 'click')
      const href = window.location.href
      const flag = href.includes('type=')
      if (flag) {
        // 移动端需要携带本来url上的参数
        const url = '/signUp?' + href.split('?')[1]
        this.$router.push({ path: url })
      } else {
        this.$router.push('/signUp')
      }
      // this.$router.push('/signUp')
    },
    nextOpaertaion () {
      ga('send', 'event', 'newUserService-forgetPsw-changePsw', 'click')
      if (this.msgType == 2) {
        if (this.country == '86') {
          const phone = this.isCn ? this.accountCn.replace(/\s*/g, '') : this.phone.replace(/\s*/g, '')
          if (!(/^1[0-9]{10}$/.test(phone))) {
            $('.telphone').addClass('errorInput')
            return
          }
        } else {
          if (!this.verfyObj.valid) {
            $('.telphone').addClass('errorInput')
            return
          }
        }
        this.isDisabeBtn = true
      }
      if (this.msgType == 1) {
        const reg = /^([\w+.-]*[+-]*\.*)+@(\w-?)+(\.\w{2,})+$/
        const account = this.isCn ? this.accountCn : this.account
        if (!reg.test(account)) {
          $('.emailArea').addClass('errorInput')
          return
        } else {
          $('.emailArea').removeClass('errorInput')
        }
        this.isDisabeBtn = true
      }
      this.isDisabeBtn = false
      let params = {
        account: this.account,
        msgType: this.msgType,
        eventType: 2
      }
      if (this.location) {
        // TVU network登录不传
        params.url = this.location
      }
      if (this.msgType == 2) {
        params.account = this.phone.replace(/\s*/g, '')
      }
      if (this.isCn) {
        params.account = this.msgType == 1 ? this.accountCn : this.accountCn.replace(/\s*/g, '')
      }
      this.$axios.post(
        '/login-service/login/sendMsgCode', params).then((res) => {
        if (res.data.errorCode == '0x0') {
          this.$message({
            message: this.$t('errorAccount'),
            type: 'success',
            duration: 3000
          })
          let userInfo = {
            account: params.account,
            msgType: this.msgType,
            country: this.country
          }
          this.$store.commit('changesUserInfo', userInfo)

          const href = window.location.href
          const flag = href.includes('type=')
          if (flag) {
            // 移动端需要携带本来url上的参数
            const url = '/verification?verifyFlag=0&' + href.split('?')[1]
            this.$router.push({ path: url })
          } else {
            this.$router.push({ path: '/verification', query: { verifyFlag: 0 } })
          }
          // this.$router.push({ path: '/verification', query: { verifyFlag: 0 } })
        } else if (res.data.errorCode == '80945301') {
          this.$message({
            message: this.$t('error80945301'),
            type: 'error',
            duration: 3000
          })
        } else if (res.data.errorCode == '80943301') {
          this.$message({
            message: this.$t('errorAccount'),
            type: 'error',
            duration: 3000
          })
        } else {
          this.$message({
            message: res.data.errorInfo,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch()
    },
    changeAccountType (val) {
      this.msgType = val
      this.account = ''
      this.phone = ''
    },
    changeNation () {
      this.blurTel()
      $('.vti__dropdown-item .hk').parent().remove()
      $('.vti__dropdown-item .tw').parent().remove()
      $('.vti__dropdown-item .mo').parent().remove()
      $('.vti__dropdown-item .cu').parent().remove()
      $('.vti__dropdown-item .sy').parent().remove()
      $('.vti__dropdown-item .kp').parent().remove()
    },
    blurTel () {
      if (this.country == '86') {
        const phone = this.phone.replace(/\s*/g, '')
        if (!(/^1[0-9]{10}$/.test(phone))) {
          $('.telphone').addClass('errorInput')
        } else {
          $('.telphone').removeClass('errorInput')
        }
      } else {
        if (this.phone && !this.verfyObj.valid) {
          $('.telphone').addClass('errorInput')
        } else {
          $('.telphone').removeClass('errorInput')
        }
      }
    },
    blurAccount () {
      const reg = /^([\w+.-]*[+-]*\.*)+@(\w-?)+(\.\w{2,})+$/
      if (!reg.test(this.account)) {
        $('.emailArea').addClass('errorInput')
      } else {
        $('.emailArea').removeClass('errorInput')
      }
    },
    blurAccountCn () {
      const reg = /^([\w+.-]*[+-]*\.*)+@(\w-?)+(\.\w{2,})+$/
      const accountCn = this.accountCn.replace(/\s*/g, '')
      if (reg.test(this.accountCn) || (/^1[0-9]{10}$/.test(accountCn))) {
        $('.emailArea').removeClass('errorInput')
      } else {
        $('.emailArea').addClass('errorInput')
      }
      if (reg.test(this.accountCn)) {
        // 邮箱
        this.msgType = 1
      }
      if ((/^1[0-9]{10}$/.test(accountCn))) {
        // 手机号
        this.msgType = 2
        this.country = '86'
      }
    },
    changeCountry (country) {
      // 被删除的国家和地方显示US
      if (country.iso2 == 'HK' || country.iso2 == 'TW' || country.iso2 == 'MO' || country.iso2 == 'CU' || country.iso2 == 'SY' || country.iso2 == 'KP') {
        country.dialCode = '1'
        const delClass = (country.iso2).toLowerCase()
        this.$nextTick(() => {
          $('.vti__selection .vti__flag').removeClass(delClass).addClass('us')
        })
      }
      this.country = country.dialCode
      ga('send', 'event', 'newUserService-register-changeCountry', 'click')
      if (Object.keys(this.verfyObj).length == 0) {
        return
      }
      this.blurTel()
    },
    validateNum (obj) {
      this.verfyObj = obj
    }
  }
}
</script>
<style lang="less" scoped>
.sign-btn {
  padding: 13px 30px!important;
  background-color: #33AB4F;
  border: none;
  color: #FFFFFF;
}

.account-form {
  position: relative;
  line-height: 40px;
  .account-icon {
    position: absolute;
    z-index: 9;
    color: #C0C4CC;
    left: 12px;
    top: 4px;
  }
}

.vue-tel-input {
  border: 1px solid #979797;
  border-radius: 8px;
  padding: 15px 20px 20px 26px;
  height: 48px;
  line-height: 48px;
}

.vue-tel-input:focus-within {
  border: 1px solid #47A31F;
  box-shadow: none;
}

/deep/.vti__dropdown-list {
  z-index: 9;
  left: -26px;
  padding: 10px 0;
  -webkit-overflow-scrolling: touch;
}

//.pc-sign-wrap /deep/.vti__dropdown-list{
//  left: -26px;
//}
//
//.mobile-sign-wrap /deep/.vti__dropdown-list{
//  left: -26px;
//}

.pc-sign-wrap /deep/.vti__dropdown-list {
  width: 358px;
}

.mobile-sign-wrap /deep/.vti__dropdown-list {
  width: 270px;
}
/*!**定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*!*/
/deep/.vti__dropdown-list::-webkit-scrollbar {
  width: 6px;
  background-color: cadetblue;
}

/*!*定义滚动条轨道 内阴影+圆角*!*/
/deep/.vti__dropdown-list::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
}

/*!*定义滑块 内阴影+圆角*!*/
/deep/.vti__dropdown-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #aaa;
}

/deep/.vti__country-code {
  font-size: 12px;
}

/deep/.vti__dropdown-item {
  height: 30px;
  line-height: 30px;
}

/deep/.vti__input {
  height: 15px;
  padding-left: 0;
}

/deep/.vti__dropdown ul li {
  display: flex;
  align-items: center;
}

/deep/.vti__dropdown ul li strong {
  display: block;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.errorTel, .warning {
  margin-top: 5px;
  line-height: 20px;
}

.exchange .warning {
  display: none;
  .warningTip, i {
    color: #d93025;
  }
}

.errorInput {
  /deep/.el-input__inner, /deep/.vue-tel-input {
    border: 1px solid #d93025
  }
}

.errorInput .warning {
  display: block !important;
}

.warningTip {
  margin-left: 5px;
}

/deep/.account-email .el-input__inner {
  padding-left: 40px;
}

.account-form {
  position: relative;
  .line-icon {
    position: absolute;
    height: 21px;
    border-right: solid .5px #979797;
    display: inline-block;
    top: 9px;
    left: 25px;
    transform: rotate(25deg);
  }
  .account-icon-cn {
    position: absolute;
    z-index: 9;
    color: #C0C4CC;
    left: 12px;
    top: 4px;
  }
  .account-input-cn /deep/.el-input__inner {
    padding-left: 72px;
  }
}
</style>
